import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrencyOptions } from '../selectors/dropdowns';

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';
import { updateProject } from '../actions/project';

import Select from './Select';

const ChangeCurrencyPopup = ({ project, index }) => {
  const dispatch = useDispatch();
  const currencies = useSelector(state => getCurrencyOptions(state));
  const useMarginCurrency = useSelector(state => +state.identity.use_margin_currency === 1);
  const onClosePopup = () => dispatch(closePopup());
  const onUpdateCurrency = currency_id => dispatch(updateProject(project.job_id, 'currency_id', project.currency_id, currency_id));

  return (
    <div className="reveal small" style={{ display: 'block', height: 'initial', overflow: 'visible', zIndex: BASE_ZINDEX + index }}>
      <div className="row">
        <div className="small-12 coluns">
          <h3>Change Project Currency</h3>
          <a className="button" style={{ position: 'absolute', top: '1rem', right: '1rem' }} onClick={onClosePopup}>Close</a>
	  {useMarginCurrency && <p>Note that the currency on items will not be updated and this may affect margin calculations.</p>}
        </div>
      </div>
      <div className="row">
        <Select
          options={currencies}
          value={project.currency_id}
          change={onUpdateCurrency}
          withMarginBottom
          inPopup
        />
      </div>
    </div>
  );
};

export default ChangeCurrencyPopup;

